import * as _ethBlockTracker2 from "eth-block-tracker";
var _ethBlockTracker = _ethBlockTracker2;
try {
  if ("default" in _ethBlockTracker2) _ethBlockTracker = _ethBlockTracker2.default;
} catch (e) {}
import * as _subscriptionManager2 from "eth-json-rpc-filters/subscriptionManager";
var _subscriptionManager = _subscriptionManager2;
try {
  if ("default" in _subscriptionManager2) _subscriptionManager = _subscriptionManager2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionManager = void 0;
const eth_block_tracker_1 = _ethBlockTracker;
// TODO: When we update this package we should be able to fix this
//  eslint-disable-next-line @typescript-eslint/no-var-requires
const createSubscriptionManager = _subscriptionManager;
const noop = () => {};
class SubscriptionManager {
  constructor(provider) {
    const blockTracker = new eth_block_tracker_1.PollingBlockTracker({
      provider: provider,
      pollingInterval: 15000,
      setSkipCacheFlag: true
    });
    const {
      events,
      middleware
    } = createSubscriptionManager({
      blockTracker,
      provider
    });
    this.events = events;
    this.subscriptionMiddleware = middleware;
  }
  async handleRequest(request) {
    const result = {};
    await this.subscriptionMiddleware(request, result, noop, noop);
    return result;
  }
  destroy() {
    this.subscriptionMiddleware.destroy();
  }
}
exports.SubscriptionManager = SubscriptionManager;
export default exports;