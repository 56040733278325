var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WalletLinkHTTP = void 0;
class WalletLinkHTTP {
  constructor(linkAPIUrl, sessionId, sessionKey) {
    this.linkAPIUrl = linkAPIUrl;
    this.sessionId = sessionId;
    const credentials = `${sessionId}:${sessionKey}`;
    this.auth = `Basic ${btoa(credentials)}`;
  }
  // mark unseen events as seen
  async markUnseenEventsAsSeen(events) {
    return Promise.all(events.map(e => fetch(`${this.linkAPIUrl}/events/${e.eventId}/seen`, {
      method: "POST",
      headers: {
        Authorization: this.auth
      }
    }))).catch(error => console.error("Unabled to mark event as failed:", error));
  }
  async fetchUnseenEvents() {
    var _a;
    const response = await fetch(`${this.linkAPIUrl}/events?unseen=true`, {
      headers: {
        Authorization: this.auth
      }
    });
    if (response.ok) {
      const {
        events,
        error
      } = await response.json();
      if (error) {
        throw new Error(`Check unseen events failed: ${error}`);
      }
      const responseEvents = (_a = events === null || events === void 0 ? void 0 : events.filter(e => e.event === "Web3Response").map(e => ({
        type: "Event",
        sessionId: this.sessionId,
        eventId: e.id,
        event: e.event,
        data: e.data
      }))) !== null && _a !== void 0 ? _a : [];
      this.markUnseenEventsAsSeen(responseEvents);
      return responseEvents;
    }
    throw new Error(`Check unseen events failed: ${response.status}`);
  }
}
exports.WalletLinkHTTP = WalletLinkHTTP;
export default exports;