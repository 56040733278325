import * as _preact2 from "preact";
var _preact = _preact2;
try {
  if ("default" in _preact2) _preact = _preact2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LaptopIcon = void 0;
const preact_1 = _preact;
function LaptopIcon(props) {
  return (0, preact_1.h)("svg", Object.assign({
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), (0, preact_1.h)("path", {
    d: "M1.8001 2.2002H12.2001V9.40019H1.8001V2.2002ZM3.4001 3.8002V7.80019H10.6001V3.8002H3.4001Z"
  }), (0, preact_1.h)("path", {
    d: "M13.4001 10.2002H0.600098C0.600098 11.0838 1.31644 11.8002 2.2001 11.8002H11.8001C12.6838 11.8002 13.4001 11.0838 13.4001 10.2002Z"
  }));
}
exports.LaptopIcon = LaptopIcon;
export default exports;