import * as _preact2 from "preact";
var _preact = _preact2;
try {
  if ("default" in _preact2) _preact = _preact2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ArrowLeftIcon = void 0;
const preact_1 = _preact;
function ArrowLeftIcon(props) {
  return (0, preact_1.h)("svg", Object.assign({
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), (0, preact_1.h)("path", {
    d: "M8.60675 0.155884L7.37816 1.28209L12.7723 7.16662H0V8.83328H12.6548L6.82149 14.6666L8 15.8451L15.8201 8.02501L8.60675 0.155884Z"
  }));
}
exports.ArrowLeftIcon = ArrowLeftIcon;
export default exports;