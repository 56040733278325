import * as _preact2 from "preact";
var _preact = _preact2;
try {
  if ("default" in _preact2) _preact = _preact2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QRCodeIcon = void 0;
const preact_1 = _preact;
function QRCodeIcon(props) {
  return (0, preact_1.h)("svg", Object.assign({
    width: "18",
    height: "18",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), (0, preact_1.h)("path", {
    d: "M3 3V8.99939L5 8.99996V5H9V3H3Z"
  }), (0, preact_1.h)("path", {
    d: "M15 21L21 21V15.0006L19 15V19L15 19V21Z"
  }), (0, preact_1.h)("path", {
    d: "M21 9H19V5H15.0006L15 3H21V9Z"
  }), (0, preact_1.h)("path", {
    d: "M3 15V21H8.99939L8.99996 19H5L5 15H3Z"
  }));
}
exports.QRCodeIcon = QRCodeIcon;
export default exports;