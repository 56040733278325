import _Web3Response from "../../relay/walletlink/type/Web3Response";
import _version from "../../version";
import _constants from "./constants";
import _utils from "./utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serializeError = void 0;
// TODO: error should not depend on walletlink. revisit this.
const Web3Response_1 = _Web3Response;
const version_1 = _version;
const constants_1 = _constants;
const utils_1 = _utils;
/**
 * Serializes an error to a format that is compatible with the Ethereum JSON RPC error format.
 * See https://docs.cloud.coinbase.com/wallet-sdk/docs/errors
 * for more information.
 */
function serializeError(error, requestOrMethod) {
  const serialized = (0, utils_1.serialize)(getErrorObject(error), {
    shouldIncludeStack: true
  });
  const docUrl = new URL("https://docs.cloud.coinbase.com/wallet-sdk/docs/errors");
  docUrl.searchParams.set("version", version_1.LIB_VERSION);
  docUrl.searchParams.set("code", serialized.code.toString());
  const method = getMethod(serialized.data, requestOrMethod);
  if (method) {
    docUrl.searchParams.set("method", method);
  }
  docUrl.searchParams.set("message", serialized.message);
  return Object.assign(Object.assign({}, serialized), {
    docUrl: docUrl.href
  });
}
exports.serializeError = serializeError;
/**
 * Converts an error to a serializable object.
 */
function getErrorObject(error) {
  if (typeof error === "string") {
    return {
      message: error,
      code: constants_1.standardErrorCodes.rpc.internal
    };
  } else if ((0, Web3Response_1.isErrorResponse)(error)) {
    return Object.assign(Object.assign({}, error), {
      message: error.errorMessage,
      code: error.errorCode,
      data: {
        method: error.method
      }
    });
  }
  return error;
}
/**
 * Gets the method name from the serialized data or the request.
 */
function getMethod(serializedData, request) {
  const methodInData = serializedData === null || serializedData === void 0 ? void 0 : serializedData.method;
  if (methodInData) {
    return methodInData;
  }
  if (request === undefined) {
    return undefined;
  } else if (typeof request === "string") {
    return request;
  } else if (!Array.isArray(request)) {
    return request.method;
  } else if (request.length > 0) {
    return request[0].method;
  }
  return undefined;
}
export default exports;