import _constants from "./constants";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serialize = exports.getErrorCode = exports.isValidCode = exports.getMessageFromCode = exports.JSON_RPC_SERVER_ERROR_MESSAGE = void 0;
const constants_1 = _constants;
const FALLBACK_MESSAGE = "Unspecified error message.";
exports.JSON_RPC_SERVER_ERROR_MESSAGE = "Unspecified server error.";
/**
 * Gets the message for a given code, or a fallback message if the code has
 * no corresponding message.
 */
function getMessageFromCode(code, fallbackMessage = FALLBACK_MESSAGE) {
  if (code && Number.isInteger(code)) {
    const codeString = code.toString();
    if (hasKey(constants_1.errorValues, codeString)) {
      return constants_1.errorValues[codeString].message;
    }
    if (isJsonRpcServerError(code)) {
      return exports.JSON_RPC_SERVER_ERROR_MESSAGE;
    }
  }
  return fallbackMessage;
}
exports.getMessageFromCode = getMessageFromCode;
/**
 * Returns whether the given code is valid.
 * A code is only valid if it has a message.
 */
function isValidCode(code) {
  if (!Number.isInteger(code)) {
    return false;
  }
  const codeString = code.toString();
  if (constants_1.errorValues[codeString]) {
    return true;
  }
  if (isJsonRpcServerError(code)) {
    return true;
  }
  return false;
}
exports.isValidCode = isValidCode;
/**
 * Returns the error code from an error object.
 */
function getErrorCode(error) {
  var _a;
  if (typeof error === "number") {
    return error;
  } else if (isErrorWithCode(error)) {
    return (_a = error.code) !== null && _a !== void 0 ? _a : error.errorCode;
  }
  return undefined;
}
exports.getErrorCode = getErrorCode;
function isErrorWithCode(error) {
  return typeof error === "object" && error !== null && (typeof error.code === "number" || typeof error.errorCode === "number");
}
function serialize(error, {
  shouldIncludeStack = false
} = {}) {
  const serialized = {};
  if (error && typeof error === "object" && !Array.isArray(error) && hasKey(error, "code") && isValidCode(error.code)) {
    const _error = error;
    serialized.code = _error.code;
    if (_error.message && typeof _error.message === "string") {
      serialized.message = _error.message;
      if (hasKey(_error, "data")) {
        serialized.data = _error.data;
      }
    } else {
      serialized.message = getMessageFromCode(serialized.code);
      serialized.data = {
        originalError: assignOriginalError(error)
      };
    }
  } else {
    serialized.code = constants_1.standardErrorCodes.rpc.internal;
    serialized.message = hasStringProperty(error, "message") ? error.message : FALLBACK_MESSAGE;
    serialized.data = {
      originalError: assignOriginalError(error)
    };
  }
  if (shouldIncludeStack) {
    serialized.stack = hasStringProperty(error, "stack") ? error.stack : undefined;
  }
  return serialized;
}
exports.serialize = serialize;
// Internal
function isJsonRpcServerError(code) {
  return code >= -32099 && code <= -32000;
}
function assignOriginalError(error) {
  if (error && typeof error === "object" && !Array.isArray(error)) {
    return Object.assign({}, error);
  }
  return error;
}
function hasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
function hasStringProperty(obj, prop) {
  return typeof obj === "object" && obj !== null && prop in obj && typeof obj[prop] === "string";
}
export default exports;